.Piso2Notas-parent > div { 
   position: relative; 
}

.Piso2Notas-parent { 
	max-width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-column-gap: 2rem;
    -moz-column-gap: 2rem;
    grid-column-gap: 2rem;
    column-gap: 2rem;
    grid-row-gap: 2rem;
    grid-row-gap: 2rem;
    row-gap: 0rem;
}

.primer-bloque {
	display: grid;
    grid-template-columns: repeat(3, 300px);
    grid-gap: 2rem;
    gap: 2rem;
    grid-area: 2 / 1 / auto / 3;
       
}
.segundo-bloque {
	grid-area: 2 / 4 / auto / 5;
    grid-gap: 2rem;
    gap: 2rem;
    display: grid;
}
.grid-item {
    position: relative;
	padding-bottom: 0.8rem;
	border-bottom: 1px solid var(--light-gray);
}


.container-banner-grande{
    width: 300px;
    height: 600px;
    margin: 0 auto;
    min-width: 100%;
    text-align: center;
    vertical-align: middle;
    background: repeating-linear-gradient(45deg, hsla(0, 0%, 90%, .1), hsla(0, 0%, 80%, .1), hsla(0, 0%, 90%, .1), hsla(0, 0%, 80%, .1) 4px, rgba(0, 0, 0, .15) 6px);
}
      
@media (max-width: 768px) {
	.Piso2Notas-parent {
		grid-template-columns: 1fr;
	}
	.primer-bloque {
		padding-bottom: 2.2rem;
        grid-template-columns: 1fr;
        grid-area: auto;
	}
    .segundo-bloque {
		padding-bottom: 0rem;
        grid-template-columns: 1fr;
        grid-area: auto;
	}
    .container-banner-chico{
        position: 4;
    }
    
}

